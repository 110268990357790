import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LeftSidebarService {
  // A BehaviorSubject to hold and emit the state of the sidebar
  private sidebarWarningSubject = new BehaviorSubject<boolean>(false);
  sidebarWarning$ = this.sidebarWarningSubject.asObservable();

  // Method to update the state
  setSidebarWarning(show: boolean): void {
    this.sidebarWarningSubject.next(show);
  }

  // Method to retrieve the current state (optional)
  getSidebarWarning(): boolean {
    return this.sidebarWarningSubject.value;
  }
}
