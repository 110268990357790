import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { GlobalService } from "../global/global.service";
@Injectable({
  providedIn: "root",
})
export class AccountService {
  constructor(private http: HttpClient, private globeService: GlobalService) {}

  createCenter(data) {
    return this.http.post(
      this.globeService.baseUrl + "account/create-care-center",
      data,
      this.globeService.getHttpOptionsAuthWithOutContentType()
    );
  }

  getCenters(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(
      this.globeService.baseUrl +
        "account/get-care-centers?page_size=" +
        pageSize +
        "&page_no=" +
        currentPage,
      filters,
      this.globeService.getHttpOptionsAuth()
    );
  }

  deleteCenter(rowElement: any) {
    return this.http.get(
      this.globeService.baseUrl +
        "account/delete-care-center?ref=" +
        rowElement,
      this.globeService.getHttpOptionsAuth()
    );
  }

  deleteChild(rowElement: any) {
    return this.http.get(
      this.globeService.baseUrl + "account/delete-child?ref=" + rowElement,
      this.globeService.getHttpOptionsAuth()
    );
  }

  deleteParent(rowElement: any) {
    return this.http.get(
      this.globeService.baseUrl + "account/delete-parent?ref=" + rowElement,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getChildren(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(
      this.globeService.baseUrl +
        "account/get-children?page_size=" +
        pageSize +
        "&page_no=" +
        currentPage,
      filters,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getParentsWithChildren(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(
      this.globeService.baseUrl +
        "account/get-parents-children?page_size=" +
        pageSize +
        "&page_no=" +
        currentPage,
      filters,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getBranches(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(
      this.globeService.baseUrl +
        "account/get-branches?page_size=" +
        pageSize +
        "&page_no=" +
        currentPage,
      filters,
      this.globeService.getHttpOptionsAuth()
    );
  }

  createBranch(data) {
    return this.http.post(
      this.globeService.baseUrl + "account/create-branch",
      data,
      this.globeService.getHttpOptionsAuthWithOutContentType()
    );
  }

  deleteBranch(rowElement: any) {
    return this.http.get(
      this.globeService.baseUrl + "account/delete-branch?ref=" + rowElement,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getAllCenters() {
    return this.http.get(
      this.globeService.baseUrl + "account/get-centers-list",
      this.globeService.getHttpOptionsAuth()
    );
  }

  getStates() {
    return this.http.get(
      this.globeService.baseUrl + "general/get-states",
      this.globeService.getHttpOptionsAuth()
    );
  }

  getCountries() {
    return this.http.get(
      this.globeService.baseUrl + "general/get-countries",
      this.globeService.getHttpOptionsAuth()
    );
  }
  getValidUsername(fname, center, lname = "") {
    return this.http.get(
      this.globeService.baseUrl +
        "general/get-valid-username?fname=" +
        fname +
        "&lname=" +
        lname +
        "&center=" +
        center,
      this.globeService.getHttpOptionsAuth()
    );
  }
  getAllBranches(centerId) {
    return this.http.get(
      this.globeService.baseUrl +
        "account/get-branches-list?center_id=" +
        centerId,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getParents(filters, pageSize, currentPage) {
    return this.http.post(
      this.globeService.baseUrl +
        "account/get-parents?page_size=" +
        pageSize +
        "&page_no=" +
        currentPage,
      filters,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getBranchesByCenter(centerID) {
    return this.http.get(
      this.globeService.baseUrl +
        "account/get-branches-by-center?center_id=" +
        centerID,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getDirectors(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(
      this.globeService.baseUrl +
        "account/get-directors?page_size=" +
        pageSize +
        "&page_no=" +
        currentPage,
      filters,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getStaff(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(
      this.globeService.baseUrl +
        "account/get-staff-list?page_size=" +
        pageSize +
        "&page_no=" +
        currentPage,
      filters,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getEducators(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(
      this.globeService.baseUrl +
        "account/get-educators?page_size=" +
        pageSize +
        "&page_no=" +
        currentPage,
      filters,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getEducatorsList(childId = null) {
    return this.http.get(
      this.globeService.baseUrl + "account/educators-list?child_id=" + childId,
      this.globeService.getHttpOptionsAuthWithOutContentType()
    );
  }

  getAllMembers() {
    let loggedUser = JSON.parse(localStorage.getItem("user"));
    if (loggedUser["role_id"] == 6) {
      let child = JSON.parse(localStorage.getItem("child"));
      return this.http.get(
        this.globeService.baseUrl +
          "account/get-all-members?child_id=" +
          child["child_id"],
        this.globeService.getHttpOptionsAuth()
      );
    } else {
      return this.http.get(
        this.globeService.baseUrl + "account/get-all-members",
        this.globeService.getHttpOptionsAuth()
      );
    }
  }

  getSiblingsDetails(parentId: string)
  {
    return this.http.get(
      this.globeService.baseUrl + "account/siblings-details?parent_id=" + parentId,
      this.globeService.getHttpOptionsAuthWithOutContentType()
    );
  }

  getEducatorListPerStatus(status = null) {
    return this.http.get(
      this.globeService.baseUrl + "account/educators-list?status=" + status,
      this.globeService.getHttpOptionsAuthWithOutContentType()
    );
  }
  getDSMemberListPerStatus(status = null) {
    return this.http.get(
      this.globeService.baseUrl + "account/ds-member-list?status=" + status,
      this.globeService.getHttpOptionsAuthWithOutContentType()
    );
  }

  getEducatorPastVisits(educatorId){
    return this.http.get(this.globeService.baseUrl + 'account/get-educator-past-visit?educator_id=' + educatorId, this.globeService.getHttpOptionsAuth());
  }
  getStaffList() {
    return this.http.get(
      this.globeService.baseUrl + "account/get-staff",
      this.globeService.getHttpOptionsAuth()
    );
  }

  createDirector(data) {
    return this.http.post(
      this.globeService.baseUrl + "account/create-director",
      data,
      this.globeService.getHttpOptionsAuthWithOutContentType()
    );
  }

  createStaff(data) {
    return this.http.post(
      this.globeService.baseUrl + "account/create-staff",
      data,
      this.globeService.getHttpOptionsAuthWithOutContentType()
    );
  }

  deleteDirector(rowElement: any) {
    return this.http.get(
      this.globeService.baseUrl + "account/delete-director?ref=" + rowElement,
      this.globeService.getHttpOptionsAuth()
    );
  }

  deleteStaff(rowElement: any) {
    return this.http.get(
      this.globeService.baseUrl + "account/delete-staff?ref=" + rowElement,
      this.globeService.getHttpOptionsAuth()
    );
  }
  deleteEducator(rowElement: any) {
    return this.http.get(
      this.globeService.baseUrl + "account/delete-educator?ref=" + rowElement,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getDirector() {
    return this.http.get(
      this.globeService.baseUrl + "account/get-director",
      this.globeService.getHttpOptionsAuth()
    );
  }
  getEducator() {
    return this.http.get(
      this.globeService.baseUrl + "account/get-educator",
      this.globeService.getHttpOptionsAuth()
    );
  }
  getEducatorData(educator_id) {
    return this.http.post(
      this.globeService.baseUrl + "account/get-educator-data", educator_id,
      this.globeService.getHttpOptionsAuthWithOutContentType()
    );
  }

  createEducator(data) {
    return this.http.post(
      this.globeService.baseUrl + "account/create-educator",
      data,
      this.globeService.getHttpOptionsAuthWithOutContentType()
    );
  }

  createParent(data) {
    return this.http.post(
      this.globeService.baseUrl + "account/create-parent",
      data,
      this.globeService.getHttpOptionsAuthWithOutContentType()
    );
  }

  createChild(data) {
    return this.http.post(
      this.globeService.baseUrl + "account/create-child",
      data,
      this.globeService.getHttpOptionsAuthWithOutContentType()
    );
  }

  getEducatorForRisk() {
    return this.http.get(
      this.globeService.baseUrl + "account/get-educator-for-risk",
      this.globeService.getHttpOptionsAuth()
    );
  }

  getParent() {
    return this.http.get(
      this.globeService.baseUrl + "account/get-parent",
      this.globeService.getHttpOptionsAuth()
    );
  }
  getParentByRef($parent_ref = "") {
    return this.http.get(
      this.globeService.baseUrl +
        "account/get-parent-by-ref?parent_ref=" +
        $parent_ref,
      this.globeService.getHttpOptionsAuth()
    );
  }
  getChildrenByParent(data) {
    return this.http.post(
      this.globeService.baseUrl + "account/get-child-parent",
      data,
      this.globeService.getHttpOptionsAuthWithOutContentType()
    );
  }

  getParentsList(filterBranch = "") {
    return this.http.get(
      this.globeService.baseUrl + "account/get-parent-list?filterBranch=" + filterBranch,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getAllEducators() {
    return this.http.get(
      this.globeService.baseUrl + "account/educators-list",
      this.globeService.getHttpOptionsAuth()
    );
  }

  getCenterChildToConnect($eventElement: any, pageSize, page, searchquery) {
    return this.http.get(
      this.globeService.baseUrl +
        "account/get-center-child-to-connect?educator_ref=" +
        $eventElement + "&page_size=" + pageSize + "&page=" + page+ "&searchquery=" + searchquery,
      this.globeService.getHttpOptionsAuth()
    );
  }

  connectChildEducator(selectedEducatorRef: any, child_ref: any, type) {
    return this.http.get(
      this.globeService.baseUrl +
        "account/connect-child-educator?educator_ref=" +
        selectedEducatorRef +
        "&child_ref=" +
        child_ref +
        "&type=" +
        type,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getParentByEducator(data) {
    return this.http.post(
      this.globeService.baseUrl + "account/get-parent-educator",
      data,
      this.globeService.getHttpOptionsAuthWithOutContentType()
    );
  }

  getChildrenList() {
    return this.http.get(
      this.globeService.baseUrl + "account/get-children-list",
      this.globeService.getHttpOptionsAuth()
    );
  }
  getChildrenPerParent() {
    return this.http.get(
      this.globeService.baseUrl + "account/get-children-per-parent",
      this.globeService.getHttpOptionsAuth()
    );
  }
  getParentByChildId(childId) {
    return this.http.get(
      this.globeService.baseUrl +
        "account/get-parents-by-child-id?id=" +
        childId,
      this.globeService.getHttpOptionsAuth()
    );
  }
  getParentsByParentID(parentIds) {
    return this.http.get(
      this.globeService.baseUrl +
        "account/get-parents-by-parent-id?id=" +
        parentIds,
      this.globeService.getHttpOptionsAuth()
    );
  }
  getEducatorsByChild(childId) {
    return this.http.get(
      this.globeService.baseUrl +
        "account/get-educator-by-child-id?id=" +
        childId,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getChildByEducatorId(educatorId) {
    return this.http.get(
      this.globeService.baseUrl +
        "account/get-children-by-educator?educator_id=" +
        educatorId,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getDailyReflectionForms(educator_id) {
    return this.http.get(
      this.globeService.baseUrl +
        "account/get-daily-reflection-by-educator-id?id=" +
        educator_id,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getChildrenByParentSelect() {
    return this.http.get(
      this.globeService.baseUrl + "account/get-parent-children",
      this.globeService.getHttpOptionsAuth()
    );
  }

  getSelectedParentByChild(childElement: any) {
    return this.http.get(
      this.globeService.baseUrl +
        "account/get-selected-parent-by-child?ref=" +
        childElement,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getNotifications(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(
      this.globeService.baseUrl +
        "account/get-notifications?page_size=" +
        pageSize +
        "&page_no=" +
        currentPage,
      filters,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getNotReadNotifications(filters: any) {
    return this.http.post(
      this.globeService.baseUrl + "account/get-not-read-notifications",
      filters,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getSentEmails(filter: any) {
    return this.http.post(
      this.globeService.baseUrl + "general/get-sent-emails",
      filter,
      this.globeService.getHttpOptionsAuth()
    );
  }
  getServiceProvider(filter: any) {
    return this.http.post(
      this.globeService.baseUrl + "general/get-service-provider",
      filter,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getEducatorsChildren(data: { educator_ids: any }) {
    return this.http.post(
      this.globeService.baseUrl + "account/get-educators-children",
      data,
      this.globeService.getHttpOptionsAuthWithOutContentType()
    );
  }

  getDirectorsByUser() {
    return this.http.get(
      this.globeService.baseUrl + "account/get-director-by-user",
      this.globeService.getHttpOptionsAuth()
    );
  }

  getAllChildren() {
    return this.http.get(
      this.globeService.baseUrl + "account/children-list",
      this.globeService.getHttpOptionsAuth()
    );
  }

  getCenterParentToConnect($eventElement: any, pageSize, page, searchquery) {
    return this.http.get(
      this.globeService.baseUrl +
        "account/get-center-parent-to-connect?child_ref=" +
        $eventElement + "&page_size=" + pageSize + "&page=" + page+ "&searchquery=" + searchquery,
      this.globeService.getHttpOptionsAuth()
    );
  }

  connectChildParent(selectedChildRef: any, parent_ref: any, type: string) {
    return this.http.get(
      this.globeService.baseUrl +
        "account/connect-child-parent?child_ref=" +
        selectedChildRef +
        "&parent_ref=" +
        parent_ref +
        "&type=" +
        type,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getChildMonthlyObservations(childId: any) {
    return this.http.get(
      this.globeService.baseUrl +
        "account/get-monthly-observations?id=" +
        childId,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getSearchMenu(data) {
    return this.http.post(
      this.globeService.baseUrl + "account/search-keyword",
      data,
      this.globeService.getHttpOptionsAuth()
    );
  }

  downloadCSV(filters) {
    return this.http.post(
      this.globeService.baseUrl + "account/search-keyword",
      filters,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getELYFOptions(value: any) {
    return this.http.get(
      this.globeService.baseUrl + "account/get-eylf-options?id=" + value,
      this.globeService.getHttpOptionsAuth()
    );
  }
  getMTOPOptions(value: any) {
    return this.http.get(
      this.globeService.baseUrl + "account/get-mtop-options?id=" + value,
      this.globeService.getHttpOptionsAuth()
    );
  }

  searchMonthlyObservations(data) {
    return this.http.post(
      this.globeService.baseUrl + "account/search-monthlyobservations",
      data,
      this.globeService.getHttpOptionsAuth()
    );
  }

  markAsReadNotifications(notificationRef: any) {
    return this.http.get(
      this.globeService.baseUrl +
        "account/mark-as-read-notification?ref=" +
        notificationRef,
      this.globeService.getHttpOptionsAuth()
    );
  }

  markAsUnReadNotifications(notificationRef: any) {
    return this.http.get(
      this.globeService.baseUrl +
        "account/mark-as-unread-notification?ref=" +
        notificationRef,
      this.globeService.getHttpOptionsAuth()
    );
  }

  deleteNotifications(notificationRef: any) {
    return this.http.get(
      this.globeService.baseUrl +
        "account/delete-notification?ref=" +
        notificationRef,
      this.globeService.getHttpOptionsAuth()
    );
  }

  deleteAlert(alertRef: any) {
    return this.http.get(
      this.globeService.baseUrl + "general/delete-alert?ref=" + alertRef,
      this.globeService.getHttpOptionsAuth()
    );
  }
  deleteAlertAttachment(filter: any) {
    return this.http.post(
      this.globeService.baseUrl + "general/delete-alert-attachment", filter,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getNotificationDetails(notificationRef: any) {
    return this.http.get(
      this.globeService.baseUrl +
        "account/get-notification?ref=" +
        notificationRef,
      this.globeService.getHttpOptionsAuth()
    );
  }

  createRegisterEducator(data) {
    return this.http.post(
      this.globeService.baseUrl + "account/create-register-educator",
      data,
      this.globeService.getHttpOptionsAuthWithOutContentType()
    );
  }

  getEducatorRegister(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(
      this.globeService.baseUrl +
        "account/get-educator-register-list?page_size=" +
        pageSize +
        "&page_no=" +
        currentPage,
      filters,
      this.globeService.getHttpOptionsAuth()
    );
  }

  deleteEducatorRegister(rowElement: any) {
    return this.http.get(
      this.globeService.baseUrl +
        "account/delete-educator-register?ref=" +
        rowElement,
      this.globeService.getHttpOptionsAuth()
    );
  }

  sendEducatorRegistrationEmail(data) {
    return this.http.post(
      this.globeService.baseUrl + "account/send-educator-register-email",
      data,
      this.globeService.getHttpOptionsAuth()
    );
  }

  checkRegistrationForm(data) {
    return this.http.post(
      this.globeService.baseUrl + "account/check-educator-register-form",
      data,
      this.globeService.getHttpOptionsAuth()
    );
  }

  approveRejectEducatorRegistration(data) {
    return this.http.post(
      this.globeService.baseUrl + "account/educator-register-approve-reject",
      data,
      this.globeService.getHttpOptionsAuth()
    );
  }

  updateParentFeedback(data) {
    return this.http.post(
      this.globeService.baseUrl + "forms/update-parent-feedback",
      data,
      this.globeService.getHttpOptionsAuth()
    );
  }

  downloadEducatorRegistrationCSV(data) {
    return this.http.post(
      this.globeService.baseUrl + "reports/educator-register-csv",
      data,
      this.globeService.getHttpOptionsAuth()
    );
  }

  downloadParentsCSV(data) {
    return this.http.post(
      this.globeService.baseUrl + "reports/parents-csv",
      data,
      this.globeService.getHttpOptionsAuth()
    );
  }

  downloadStaffCSV(data) {
    return this.http.post(
      this.globeService.baseUrl + "reports/staff-csv",
      data,
      this.globeService.getHttpOptionsAuth()
    );
  }

  downloadEducatorsCSV(data) {
    return this.http.post(
      this.globeService.baseUrl + "reports/educators-csv",
      data,
      this.globeService.getHttpOptionsAuth()
    );
  }

  downloadChildrenCSV(data) {
    return this.http.post(
      this.globeService.baseUrl + "reports/children-csv",
      data,
      this.globeService.getHttpOptionsAuth()
    );
  }

  downloadSingleReportCSV(data) {
    return this.http.post(
      this.globeService.baseUrl + "reports/get-single-report-csv",
      data,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getDirectorBranch(data = {}) {
    return this.http.get(
      this.globeService.baseUrl + "account/director-branches",
      this.globeService.getHttpOptionsAuth()
    );
  }

  createRegisterStaff(data) {
    return this.http.post(
      this.globeService.baseUrl + "account/create-register-staff",
      data,
      this.globeService.getHttpOptionsAuthWithOutContentType()
    );
  }

  getStaffRegister(filters: any, pageSize: number, currentPage: number) {
    return this.http.post(
      this.globeService.baseUrl +
        "account/get-staff-register-list?page_size=" +
        pageSize +
        "&page_no=" +
        currentPage,
      filters,
      this.globeService.getHttpOptionsAuth()
    );
  }

  deleteStaffRegister(rowElement: any) {
    return this.http.get(
      this.globeService.baseUrl +
        "account/delete-staff-register?ref=" +
        rowElement,
      this.globeService.getHttpOptionsAuth()
    );
  }

  sendStaffRegistrationEmail(data) {
    return this.http.post(
      this.globeService.baseUrl + "account/send-staff-register-email",
      data,
      this.globeService.getHttpOptionsAuth()
    );
  }

  checkStaffRegistrationForm(data) {
    return this.http.post(
      this.globeService.baseUrl + "account/check-staff-register-form",
      data,
      this.globeService.getHttpOptionsAuth()
    );
  }

  approveRejectStaffRegistration(data) {
    return this.http.post(
      this.globeService.baseUrl + "account/staff-register-approve-reject",
      data,
      this.globeService.getHttpOptionsAuth()
    );
  }

  getStaffSingle() {
    return this.http.get(
      this.globeService.baseUrl + "account/get-staff-single",
      this.globeService.getHttpOptionsAuth()
    );
  }

  getViewForChildForm(data) {
    return this.http.post(
      this.globeService.baseUrl + "account/download-child-form",
      data,
      this.globeService.getHttpOptionsAuth()
    );
  }

  setBranchDirector(data) {
    return this.http.post(
      this.globeService.baseUrl + "account/set-director-branch",
      data,
      this.globeService.getHttpOptionsAuth()
    );
  }
}
